import mercedes from "./assets/MercedesTms.svg"
import cloudit from './assets/Cloudit.svg'
import buildspace from './assets/buildspace.svg'
import cointrack from './assets/cointrack.svg'
import weather from './assets/weather.svg'
import metaexpo from './assets/metaexpo.svg'
import meetup from './assets/meetup.png'
import apizen from './assets/api-zen.svg'

const projectCardDetails = [
    {
        img: meetup,
        skills: ['NextJS', 'TypeScript', 'Socket.io', 'PeerJs'],
        name: "MeetUp",
        desc: "No sign-ups, no downloads—just smooth, secure video calls with MeetUp. Stay connected with ease.",
        link: "https://meetup-production-40ff.up.railway.app/"
    },
    {
        img: apizen,
        skills: ['NextJS', 'Axios', 'TailwindCSS', 'Shiki'],
        name: "APIzen",
        desc: "Optimize Your APIs, Minimize Latency. APIzen is a user-friendly tool for sending HTTP requests, adding params, headers, and viewing formatted responses.",
        link: "https://api-zen.vercel.app/"
    },
    {
        img: mercedes,
        skills: ['ReactJS', 'NodeJS', 'MongoDB', 'TailwindCSS'],
        name: "MercedesTMS",
        desc: "MercedesTMS is a Tyre management system for F1 race weekends. Where you can add, allocate, manage race sessions and Tyre sets.",
        link: "https://mercedestms.netlify.app/"
    },
    {
        img: metaexpo,
        skills: ['NextJS', 'Framer motion', 'TailwindCSS'],
        name: "Metaexpo",
        desc: "Metaexpo is a contemporary metaverse-themed website designed to practice animation abilities utilising the NextJS framework.",
        link: "https://metaexpo.vercel.app/"
    },
    {
        img: buildspace,
        skills: ['ReactJS', 'TailwindCSS'],
        name: "Buildspace",
        desc: "Buildspace is a SaaS landing page that I developed to experiment with react components, responsive design, and new libraries.",
        link: "https://buildspacecompany.netlify.app/"
    },
    {
        img: cloudit,
        skills: ['ReactJS', 'TailwindCSS'],
        name: "Cloudit",
        desc: "Cloudit is a landing page I developed for a cloud management company in order to better my react skills.",
        link: "https://clouditcompany.netlify.app/"
    },
    /*  {
         img: cointrack,
         skills: ['ReactJS', 'Axios', 'API'],
         name: "Cointrack",
         desc: "Cointrack is a website that contains the top 50 cryptocurrencies and it's detailed information.",
         link: "https://cryptocointrack.netlify.app/"
     }, */
    /* {
        img: weather,
        skills: ['ReactJS', 'Axios', 'API'],
        name: "Weather Daily",
        desc: "Weather Daily is a website where you can find out about the weather in any location in detail.",
        link: "https://weather-app-sadcodes.netlify.app/"
    } */
]

export default projectCardDetails